import React from 'react'
import SbEditable from 'storyblok-react'
import ACSBlogCard from '@system/blog-card'

const BlogCard = (props) => {
  return (
    <SbEditable content={props.blok}>
      <ACSBlogCard {...props.blok} />
    </SbEditable>
  )
}

export default BlogCard
